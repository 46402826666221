import styled from "@emotion/styled";
import { Box, Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import { hideSidebar, showSidebar } from "../../redux/slices/chatSlice";

import device, { size } from "../../util/device";
import { hideModal, showModal } from "../../redux/slices/modalSlice";

const MyContainer = styled.div(props => `
    width: calc(100% - 40px);
    zIndex: 11;
    padding: 20px;
    background-color: black;
    color: white;
    min-height: calc(100% - 40px);

    @media ${device.laptop} {
        background-color: transparent;
    }
`);

const MyImg = styled.img(props => `
    width: 100%;
    height: 100%;
    
    @media ${device.laptop} {
        height: calc(100vh - 50px);
        width: auto;  
    }
`);

const SubImg = styled.img(props => `
    width: 100%;
    height: 100%;
    
    @media ${device.laptop} {
        height: calc(50vh - 38px);
        width: auto;
    }
`);

const GoBack = styled.div(props => `
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 27px;
    cursor: pointer;

    @media ${device.laptop} {
        display: none;
    }
`);

const DestinationDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();

    const [images, setImages] = useState([]);
    const [width, setWindowWidth] = useState(0);

    const importImages = async () => {
        let images = [];
        for (let i = 1; i < 5; i++) {
            const image = await import(`../../images/${params.groupId}/${params.id}/${i}.png`);
            images.push(image.default);
        }
        setImages(images);
    }

    useEffect(() => {
        dispatch(hideSidebar());
        importImages();
    }, []);
    
    useEffect(() => { 

     updateDimensions();

     window.addEventListener("resize", updateDimensions);
     return () => 
       window.removeEventListener("resize",updateDimensions);
    }, [])

    const goBack = () => {
        navigate(`/destinations/${params.groupId}`);
        dispatch(showSidebar());
    }

    const updateDimensions = () => {
        const width = window.innerWidth
        setWindowWidth(width)
    }

    const goToComingSoon = () => {
        dispatch(showModal());
    }

    if (width > size.laptop) {
        return (
            <MyContainer>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        {images.length ? (
                                            <MyImg src={images[0]} onClick={goToComingSoon} alt='' />
                                        ):<></>}
                                        
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Grid container spacing={2}>
                            {images.length?(
                                images.map((image, index) => (
                                    <Grid item xs={6} key={index}>
                                        <SubImg src={image} onClick={goToComingSoon} alt='' />
                                    </Grid>
                                ))
                            ):<></>}
                        </Grid>
                    </Grid>
                </Grid>
            </MyContainer>
        );
    } else {
        return (
            <Box sx={{ flexGrow: 1 }} style={{color: 'white', backgroundColor: 'black', height: '200vh',}}>
                <Grid container rowSpacing={2} columnSpacing={1}>
                    <Grid item xs={6}>
                        <img src={images[0]} style={{width: '70%'}} onClick={goToComingSoon} alt='' />
                    </Grid>
                    <Grid item xs={6}>
                        <h4>Bali, Indonesia (text 1)</h4>
                        <div>fheufhuihfehfuiehfuehfuehfuehfuh</div>
                        <div>fheufhuihfehfuiehfuehfuehfuehfuh</div>
                        <div>fheufhuihfehfuiehfuehfuehfuehfuh</div>
                        <div>fheufhuihfehfuiehfuehfuehfuehfuh</div>
                    </Grid>
                    <Grid item xs={12}>
                        <div>Text two - change with each picture at bottom as scroll</div>

                        <div>(4 texts one for each picture)</div>
                    </Grid>
                    <Grid item xs={12}>
                        <Swiper spaceBetween={20} slidesPerView={2}>
                            {images.map((image, index) => (
                                <SwiperSlide style={{width: '400px !important'}} key={index}>
                                    <img src={image} style={{width: '70%'}} onClick={goToComingSoon} alt='' />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Grid>
                </Grid>
                <GoBack onClick={goBack}>X</GoBack>
            </Box>
        );
    }
}

export default DestinationDetail;
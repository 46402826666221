import styled from "@emotion/styled";
import { Container } from "@mui/material";

const MyContainer = styled.div(props => `
    background-color: #1E1F23;
    height: 100px;
`);

const Header = () => {
    return (
        <MyContainer>
            <Container></Container>
        </MyContainer>
    );
}

export default Header;
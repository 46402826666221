import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import "./Navbar.css";
import lok from "./butlogo.png";

function Navbar() {
  const navRef = useRef();
  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div baseColor="#1E1F23" highlightColor="#1E1F23" className="head ">
      {" "}
      <br />
      <header>
        <a href="./">
          <img src={lok} style={{ width: "40px" }} />
        </a>
        <nav ref={navRef}>
          <a href="/" className="font_nav_text a text_head">
            Home
          </a>
          <a
            href="https://flutter-chat.vercel.app/"
            className="font_nav_text a text_head"
          >
            Explore new destination{" "}
          </a>
          <a href="/Becomeinfluencer" className="font_nav_text a text_head">
            Become an influencer
          </a>

          <a
            href="/forbusiness"
            id="style-2"
            className="font_nav_text a text_head"
          >
            For businesses
          </a>
          <a href="/signin" id="style-2" className="font_nav_text a text_head">
            Sign in
          </a>

          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </div>
  );
}

export default Navbar;

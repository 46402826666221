import Item from "../Item";

import image from '../../../img/swiper2.png';

import styled from "@emotion/styled";
import './signupModal.css';

const ModalBack = styled.div(props => ({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(15px)'
}));

const Container = styled.div(props => ({
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: props.visible ? 'block' : 'none',
    zIndex: 12
}));

const SignupModal = ({visible, setVisible}) => {
    return (
        <Container visible={visible}>
            <ModalBack onClick={() => setVisible(false)} />
            {/* <Modal>This page is coming soon ...</Modal> */}
            <Item title={"This page doesn't exist"} content="This page is coming soon" btn={"OK"} backgroundImage={image} />
        </Container>
    );
}

export default SignupModal;
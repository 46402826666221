import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Grid } from "@mui/material";
import Celebs from "./Celebs";
import Artist from "./Artist";
import Insta from "./Insta.jsx";
import "./Load.css";
export default function LabTabs() {
  const [value, setValue] = React.useState("1");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <>
          {isMobile ? (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Grid
                container
                spacing={2}
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "1" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "1")}
                  >
                    Celebs
                  </button>
                </Grid>
                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "2" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "2")}
                  >
                    Artists
                  </button>
                </Grid>

                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "3" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "3")}
                  >
                    Instagram
                  </button>
                </Grid>

                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "4" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "4")}
                  >
                    Youtube
                  </button>
                </Grid>

                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "5" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "5")}
                  >
                    Crypto
                  </button>
                </Grid>
                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "6" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "6")}
                  >
                    TikTok
                  </button>
                </Grid>
              </Grid>
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              <Grid
                container
                spacing={2}
                style={{ justifyContent: "center", textAlign: "center" }}
              >
                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "1" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "1")}
                  >
                    Celebs
                  </button>
                </Grid>
                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "2" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "2")}
                  >
                    Artists
                  </button>
                </Grid>

                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "3" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "3")}
                  >
                    Instagram
                  </button>
                </Grid>

                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "4" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "4")}
                  >
                    Youtube
                  </button>
                </Grid>

                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "5" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "5")}
                  >
                    Crypto
                  </button>
                </Grid>
                <Grid item md={2} lg={2}>
                  {" "}
                  <button
                    class="button-40"
                    variant={value === "6" ? "contained" : "outlined"}
                    onClick={() => handleChange(null, "6")}
                  >
                    TikTok
                  </button>
                </Grid>
              </Grid>
            </Box>
          )}
        </>
        <TabPanel value="1">
          <Celebs />
        </TabPanel>
        <TabPanel value="2">
          <Artist />
        </TabPanel>
        <TabPanel value="3">
          <Insta />
        </TabPanel>
        <TabPanel value="4">
          {" "}
          <Celebs />
        </TabPanel>
        <TabPanel value="5">
          {" "}
          <Artist />
        </TabPanel>
        <TabPanel value="6">
          {" "}
          <Insta />
        </TabPanel>
      </TabContext>
    </Box>
  );
}

import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import logo from "./endless.png";
import MainGallery from "../GalleryComponent/MainGallery";
import "./Load.css";
const style = {
  height: 30,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

function App() {
  const [items, setItems] = useState(Array.from({ length: 1 }));

  const fetchMoreData = () => {
    // a fake async api call like which sends
    // 20 more records in 1.5 secs
    setTimeout(() => {
      setItems([...items, ...Array.from({ length: 1 })]);
    }, 4000);
  };

  return (
    <div style={{ textAlign: "center", backgroundColor: "#000" }}>
      <div className="  navu">
        <div
          className="py-4 aboutBtnContainer"
          style={{
            background: "#1E1F23 ",
          }}
        >
          {" "}
          <img src={logo} style={{ width: "30%" }} />
        </div>
      </div>
      <InfiniteScroll
        dataLength={items.length}
        next={fetchMoreData}
        hasMore={true}
        loader={
          <div class="preloader-1">
            <div style={{ color: "#fff" }}>Loading</div>
            <span class="line line-1"></span>
            <span class="line line-2"></span>
            <span class="line line-3"></span>
            <span class="line line-4"></span>
            <span class="line line-5"></span>
            <span class="line line-6"></span>
            <span class="line line-7"></span>
            <span class="line line-8"></span>
            <span class="line line-9"></span>
          </div>
        }
      >
        {items.map((i, index) => (
          <div>
            <MainGallery />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
}

export default App;

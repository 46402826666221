import React from "react";
import "./HeroSection.css";
import { Grid } from "@mui/material";
import Logo from "./butlogo.png";
import los from "./los.png";
function HeroSection() {
  return (
    <div>
      <Grid container className="background_hero">
        <Grid item lg={8} md={8} xs={8} sm={6} className="left_hero">
          <a href="/">
            {" "}
            <img src={Logo} style={{ width: "10%", padding: "20px" }} /> &nbsp;
          </a>
         
          &nbsp; &nbsp; &nbsp; &nbsp;
          <a href="https://flutter-chat.vercel.app/" className="text_head">
            explore new destination{" "}
          </a>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <a href="/Becomeinfluencer" className="text_head">
            become an influencer{" "}
          </a>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <a href="/forbusiness" className="text_head">
            for businesses{" "}
          </a>
          &nbsp; &nbsp; &nbsp; &nbsp;
          <a href="/signin" className="text_head">
            sign in
          </a>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <img src={los} style={{ width: "100%", padding: "20px" }} />
          <br />
          <br />
          <br />
          <br />
          <h4 style={{ color: "#ABAAAA", padding: "30px" }}>
            Discover <span style={{ color: "#fff" }}>new </span> destinations
            <br />
            <br />
          </h4>
        </Grid>

        <Grid item lg={4} md={4} xs={4} sm={6} className="right_hero"></Grid>
      </Grid>
    </div>
  );
}

export default HeroSection;

import React from "react";
import { Grid } from "@mui/material";
import mobileimage from "./hero.gif";
function MobileHeroSection() {
  return (
    <React.Fragment>
      <Grid container spacing={2} style={{ backgroundColor: "#1E1F23" }}>
        <Grid md={12} lg={12} xs={12} sm={12}>
          <br />

          <h4 className="text_head_under" style={{ textAlign: "center" }}>
            New Ways to Adventure.
          </h4>
          <h6
            style={{ color: "#ABAAAA", padding: "30px", textAlign: "center" }}
          >
            Discover <span style={{ color: "#fff" }}>new </span> destinations
          </h6>
        </Grid>
        <Grid md={12} lg={12} xs={12} sm={12}>
          <img src={mobileimage} style={{ width: "100%", height: "70vh" }} />
        </Grid>
        <div className="style_div">
          {" "}
          <a className="btn-grad-try"> T r y</a>
        </div>
      </Grid>
    </React.Fragment>
  );
}

export default MobileHeroSection;

import styled from "@emotion/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import { Button } from "@mui/material";
import { Pagination } from "swiper";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import device from "../../util/device";
import { useDispatch } from "react-redux";
import { hideSidebar } from "../../redux/slices/chatSlice";

const MySwiper = styled(Swiper)(props => `
    width: 100%;
    cursor: grab;
    margin: 0;
    height: 100%;

    @media ${device.laptop} {
        height: 100%;
    }
`);

const MySwiperSlide = styled(SwiperSlide)(props => `
    width: fit-content;
    height: 100%;   
    &:hover img {
        filter: blur(5px);
    }

    @media ${device.laptop} {

        &:hover button {
            opacity: 1;
        }
    }
`);

const MyButton = styled(Button)`
    text-transform: none !important;
    background: #49FFDE !important;
    border-radius: 37px !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    text-align: center !important;
    

    color: #000000 !important;
    width: 100%;
    height: 100%;
    transition: all 0.2s;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;


    &:hover{
        background-color: #34b9a1 !important;
    }
    @media ${device.laptop} {
        display: block;
        width: 213px !important;
        height: 48px !important;
        
        top: calc(50% - 24px);
        left: calc(50% - 106px);
    }
`;


const MyImg = styled.img(props => ({
    width: '100%',
    height: '100%'
    // height: '600px',
    
}));


const Destinations = () => {
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [image1, setImage1] = useState('');
    const [image2, setImage2] = useState('');
    const [image3, setImage3] = useState('');

    const imageDetailShow = (index) => {
        navigate(`/destination-detail/${params.id}/${index}`);
        dispatch(hideSidebar());
    }
    useEffect(() => {
        const importImages = async () => {
            setImage1((await import(`../../images/${params.id}/1/main.png`)).default);
            setImage2((await import(`../../images/${params.id}/2/main.png`)).default);
            setImage3((await import(`../../images/${params.id}/3/main.png`)).default);
        }
        importImages();
    }, [params.id]);


    return (
        <MySwiper
            slidesPerView={"auto"}
            spaceBetween={40}
            pagination={{
                clickable: true,
            }}
            modules={[Pagination]}
        >
            <MySwiperSlide>
                <MyImg src={image1} alt='' />
                <MyButton onClick={()=>imageDetailShow(1)} variant="contained">Go to trip</MyButton>
            </MySwiperSlide>
            <MySwiperSlide>
                <MyImg src={image2} alt='' />
                <MyButton onClick={()=>imageDetailShow(2)} variant="contained">Go to trip</MyButton>
            </MySwiperSlide>
            <MySwiperSlide>
                <MyImg src={image3} alt='' />
                <MyButton onClick={()=>imageDetailShow(3)} variant="contained">Go to trip</MyButton>
            </MySwiperSlide>
        </MySwiper>
    );
}

export default Destinations;
import React from "react";
import "./ImagesHover.css";

import "./css/card23.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

const images = [
  { id: 1, src: require("./subtwentythree/1.png") },
  { id: 2, src: require("./subtwentythree/2.png") },
  { id: 3, src: require("./subtwentythree/3.png") },
  { id: 4, src: require("./subtwentythree/4.png") },
  { id: 5, src: require("./subtwentythree/5.png") },
  { id: 6, src: require("./subtwentythree/6.png") },
  { id: 7, src: require("./subtwentythree/7.png") },
  { id: 8, src: require("./subtwentythree/8.png") },
  { id: 9, src: require("./subtwentythree/9.png") },
];

export default function App() {
  return (
    <>
      <div class="twentythree">
        <div class="twentythree__side twentythree__side--front-1">
          <div class="twentythree__title twentythree__title--1">
            <i class="fas fa-paper-plane"></i>

            <h4 class="text">A Golden Parisian Escape</h4>
          </div>
        </div>
        <div class="twentythree__side twentythree__side--back twentythree__side--back-1">
          <div class="twentythree__cta">
          <MDBCarousel showControls dealy={1000} style={{ height: "100%" }}>
          {images.map((image) => (
            <MDBCarouselItem
              key={image.id}
              className="card1"
              itemId={image.id}
              src={image.src}
              alt="..."
            />
          ))}
        </MDBCarousel>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import HeroSection from "../MainComponent/HeroSectionHome";
import Navbar from "../MainComponent/Navbar";
import ComingSoon from "../MainComponent/ComingSoon";
import MobileHeroSection from "../MainComponent/MobileHeroSection";
import Cursor from "./Cusrsor";
import classNames from "classnames";
import InfiniteScrollImage from "../MainComponent/InfiniteScroll";
import Modal from "../MainComponent/SignupScreen";
import useSticky from "../MainComponent/StickyNavbar";
function Garden() {
  const { sticky, stickyRef } = useSticky();
  const theme = useTheme();
  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <div className="garden">
      {/* <Slider /> */}
      {!smallScreen && (
        <div className="mainContent">
          <div className="pageCon">
            {" "}
            <nav ref={stickyRef} className={classNames("nav flex", { sticky })}>
              <a href="/" className="text_head">
                Home
              </a>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <a href="https://flutter-chat.vercel.app/" className="text_head">
                explore new destination{" "}
              </a>
              &nbsp; &nbsp; &nbsp; &nbsp;
              <a href="/Becomeinfluencer" className="text_head">
                become an influencer{" "}
              </a>
            
              &nbsp; &nbsp; &nbsp; &nbsp;
              <a href="/signin" className="text_head">
                sign in
              </a>
            </nav>
            {sticky && (
              <div
                style={{
                  height: `${stickyRef.current?.clientHeight}px`,
                }}
              />
            )}
            <ComingSoon />
          </div>
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
        <div className="mainContent">
          <div className="pageCon">
            {" "}
            <Navbar />
            <ComingSoon />
          </div>
        </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
  );
}

export default Garden;

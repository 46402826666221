import React from "react";
import "./ImagesHover.css";

import "./css/card20.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

const images = [
  { id: 1, src: require("./subtwenty/1.png") },
  { id: 2, src: require("./subtwenty/2.png") },
  { id: 3, src: require("./subtwenty/3.png") },
  { id: 4, src: require("./subtwenty/4.png") },
  { id: 5, src: require("./subtwenty/5.png") },
  { id: 6, src: require("./subtwenty/6.png") },
];

export default function App() {
  return (
    <>
      <div class="twenty">
        <div class="twenty__side twenty__side--front-1">
          <div class="twenty__title twenty__title--1">
            <i class="fas fa-paper-plane"></i>

            <h4 class="text">
              Roaring Down the 101: A Journey of Two-Wheeled Fr…
            </h4>
          </div>
        </div>
        <div class="twenty__side twenty__side--back twenty__side--back-1">
          <div class="twenty__cta">
            <MDBCarousel showControls dealy={1000} style={{ height: "100%" }}>
              {images.map((image) => (
                <MDBCarouselItem
                  key={image.id}
                  className="card1"
                  itemId={image.id}
                  src={image.src}
                  alt="..."
                />
              ))}
            </MDBCarousel>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import Vector from "./Vector.png";
import Logo from "./Logo.png";
import card1 from "./card1.png";
import card2 from "./card2.png";
import card3 from "./card3.png";
import card4 from "./card4.png";
import InfluencerTabs from "./InfluencerTabs";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2500,
});
const MissionStatement = () => {
  return (
    <div className="">
      <br />
      <br />
      <br />
      <br />
      <div className="container">
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <br />
            <br />
            <div data-aos="fade-right">
              {" "}
              <MDBCard
                style={{
                  Width: "100%",
                  height: "",
                  background: "#1E3231",
                  borderRadius: "30px",
                }}
              >
                <MDBRow className="g-0">
                  <MDBCol md="6">
                    <MDBCardImage
                      src={card1}
                      alt="..."
                      fluid
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </MDBCol>
                  <MDBCol md="6">
                    <MDBCardBody>
                      <MDBCardTitle
                        style={{
                          color: "#fff",
                          fontSize: "24px",
                          fontWeight: "700",
                        }}
                      >
                        Endless knowledge empowered by AI
                      </MDBCardTitle>
                      <br />
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "19px",
                          fontWeight: "400",
                        }}
                      >
                        Allow AI to be your travel guide. Finding the best
                        hotels, restaurants, and entertainment. Whether you’re
                        looking for a luxury vacation or planning a trip on a
                        budget, we can find help you plan the best vacation.
                      </span>

                      <span>
                        <br />
                        <br />
                        <Divider
                          variant="middle"
                          style={{ color: "#fff", background: "#fff" }}
                        />
                      </span>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <br />
            <br />
            <div>
              {" "}
              <MDBCard
                style={{
                  Width: "100%",
                  height: "",
                  background: "#1E3231",
                  borderRadius: "30px",
                }}
              >
                <MDBRow className="g-0">
                  <MDBCol md="6">
                    <MDBCardImage
                      src={card2}
                      alt="..."
                      fluid
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </MDBCol>
                  <MDBCol md="6">
                    <MDBCardBody>
                      <MDBCardTitle
                        style={{
                          color: "#fff",
                          fontSize: "24px",
                          fontWeight: "700",
                        }}
                      >
                        Discover new passions
                      </MDBCardTitle>
                      <br />
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "19px",
                          fontWeight: "400",
                        }}
                      >
                        Create new connections with travelers. Allow the
                        community to help you discover new passions, from
                        learning yoga taking a cooking class, or making new
                        friends to go on adventures with.
                      </span>

                      <span>
                        <br />
                        <br />
                        <Divider
                          variant="middle"
                          style={{ color: "#fff", background: "#fff" }}
                        />
                      </span>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <br />
            <br />
            <div>
              {" "}
              <MDBCard
                style={{
                  Width: "100%",
                  height: "",
                  background: "#1E3231",
                  borderRadius: "30px",
                }}
              >
                <MDBRow className="g-0">
                  <MDBCol md="6">
                    <MDBCardBody>
                      <MDBCardTitle
                        style={{
                          color: "#fff",
                          fontSize: "24px",
                          fontWeight: "700",
                        }}
                      >
                        Explore new destinations
                      </MDBCardTitle>
                      <br />
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "19px",
                          fontWeight: "400",
                        }}
                      >
                        You never know what you might discover in your travels.
                        Find the new travel adventures, or maybe you might be
                        passionate about being on a cruise, discovering a more
                        luxurious way to travel.
                      </span>

                      <span>
                        <br />
                        <br />
                        <Divider
                          variant="middle"
                          style={{ color: "#fff", background: "#fff" }}
                        />
                      </span>
                    </MDBCardBody>
                  </MDBCol>
                  <MDBCol md="6">
                    <MDBCardImage
                      src={card4}
                      alt="..."
                      fluid
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </div>
          </Grid>
          <p
            style={{
              color: "#000",
              fontSize: "84px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            Coming Soon
          </p>
        </Grid>
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="container">
        <Grid container spacing={2}>
          <InfluencerTabs />
        </Grid>
      </div>
    </div>
  );
};

export default MissionStatement;

import React from "react";
import "./css/cards2.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

const images = [
  { id: 1, src: require("./subtwo/1.png") },
  { id: 2, src: require("./subtwo/2.png") },
  { id: 3, src: require("./subtwo/3.png") },
  { id: 4, src: require("./subtwo/4.png") },
  { id: 5, src: require("./subtwo/5.png") },
  { id: 6, src: require("./subtwo/6.png") },
  { id: 7, src: require("./subtwo/7.png") },
  { id: 8, src: require("./subtwo/8.png") },
  { id: 9, src: require("./subtwo/9.png") },
];

export default function App() {
  return (
    <React.Fragment>
      <div className="cardzt">
        <div className="cardzt__side cardzt__side--front-1">
          <div className="cardzt__title cardzt__title--1">
            <h4 className="text">Big 'Bling' Ben</h4>
          </div>
        </div>
        <div className="cardzt__side cardzt__side--back cardzt__side--back-1">
          <div className="cardzt__cta">
            <MDBCarousel showControls dealy={1000} style={{ height: "100%" }}>
              {images.map((image) => (
                <MDBCarouselItem
                  key={image.id}
                  className="card1"
                  itemId={image.id}
                  src={image.src}
                  alt="..."
                />
              ))}
            </MDBCarousel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import { keyframes } from "@emotion/react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Button } from "@mui/material";

import "swiper/css";
import "swiper/css/pagination";
import styled from "@emotion/styled";

import image1 from "../../../img/1.png";
import image2 from "../../../img/2.png";
import image3 from "../../../img/3.png";
import image4 from "../../../img/4.png";

const presentation = keyframes`
    0% {
        transform: translateX(1000px);
    }
    100% {
        transform: translateX(0px);
    }
`;

const MySwiper = styled(Swiper)((props) => ({
  width: "calc(100% - 40vh - 40px)",
  cursor: "grab",
  margin: "0",
}));

const MySwiperSlide = styled(SwiperSlide)((props) => ({
  transition: "all 0.2s",
  width: "500px",
  ":hover img": {
    filter: "blur(5px)",
  },
  button: {
    transition: "all 0.2s",
    opacity: "0",
    position: "absolute",
    top: "calc(50% - 24px)",
    left: "calc(50% - 106px)",
    width: "212px",
    height: "48px",
  },
  ":hover button": {
    opacity: "1",
  },
  animation: `${presentation} 1s ease`,
}));

const MyButton = styled(Button)`
  text-transform: none !important;
  background: #49ffde !important;
  border-radius: 37px !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  text-align: center !important;
  width: 213px !important;
  height: 48px !important;

  color: #000000 !important;

  &:hover {
    background-color: #34b9a1 !important;
  }
`;

const MyImg = styled.img((props) => ({
  width: "100%",
  // height: '600px',
}));

const SwiperModal = ({ imageDetailShow, img }) => {
  return (
    <MySwiper
      slidesPerView={"auto"}
      centeredSlides={true}
      spaceBetween={40}
      pagination={{
        clickable: true,
      }}
      modules={[Pagination]}
    >
      {img === 1 ? (
        <>
          <MySwiperSlide>
            <MyImg src={image1} alt="" />
            <MyButton
              className="signup-btn"
              onClick={imageDetailShow}
              variant="contained"
            >
              Go to trip
            </MyButton>
          </MySwiperSlide>
          <MySwiperSlide>
            <MyImg src={image2} alt="" />
            <MyButton
              className="signup-btn"
              onClick={imageDetailShow}
              variant="contained"
            >
              Go to trip
            </MyButton>
          </MySwiperSlide>
          <MySwiperSlide>
            <MyImg src={image3} alt="" />
            <MyButton
              className="signup-btn"
              onClick={imageDetailShow}
              variant="contained"
            >
              Go to trip
            </MyButton>
          </MySwiperSlide>
          <MySwiperSlide>
            <MyImg src={image4} alt="" />
            <MyButton
              className="signup-btn"
              onClick={imageDetailShow}
              variant="contained"
            >
              Go to trip
            </MyButton>
          </MySwiperSlide>
        </>
      ) : (
        <></>
      )}

      {img === 2 ? (
        <>
          <MySwiperSlide>
            <MyImg src={image4} alt="" />
            <MyButton onClick={imageDetailShow} variant="contained">
              Go to trip
            </MyButton>
          </MySwiperSlide>
          <MySwiperSlide>
            <MyImg src={image1} alt="" />
            <MyButton onClick={imageDetailShow} variant="contained">
              Go to trip
            </MyButton>
          </MySwiperSlide>
          <MySwiperSlide>
            <MyImg src={image2} alt="" />
            <MyButton onClick={imageDetailShow} variant="contained">
              Go to trip
            </MyButton>
          </MySwiperSlide>
          <MySwiperSlide>
            <MyImg src={image3} alt="" />
            <MyButton onClick={imageDetailShow} variant="contained">
              Go to trip
            </MyButton>
          </MySwiperSlide>
        </>
      ) : (
        <></>
      )}

      {img === 3 ? (
        <>
          <MySwiperSlide>
            <MyImg src={image2} alt="" />
            <MyButton onClick={imageDetailShow} variant="contained">
              Go to trip
            </MyButton>
          </MySwiperSlide>
          <MySwiperSlide>
            <MyImg src={image3} alt="" />
            <MyButton onClick={imageDetailShow} variant="contained">
              Go to trip
            </MyButton>
          </MySwiperSlide>
          <MySwiperSlide>
            <MyImg src={image4} alt="" />
            <MyButton onClick={imageDetailShow} variant="contained">
              Go to trip
            </MyButton>
          </MySwiperSlide>
          <MySwiperSlide>
            <MyImg src={image1} alt="" />
            <MyButton onClick={imageDetailShow} variant="contained">
              Go to trip
            </MyButton>
          </MySwiperSlide>
        </>
      ) : (
        <></>
      )}
    </MySwiper>
  );
};

export default SwiperModal;

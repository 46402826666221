import React from "react";
import "./css/card3.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

const images = [
  { id: 1, src: require("./subthree/1.png") },
  { id: 2, src: require("./subthree/2.png") },
  { id: 3, src: require("./subthree/3.png") },
  { id: 4, src: require("./subthree/4.png") },
  { id: 5, src: require("./subthree/5.png") },
  { id: 6, src: require("./subthree/6.png") },
  { id: 7, src: require("./subthree/7.png") },
  { id: 8, src: require("./subthree/8.png") },
  { id: 9, src: require("./subthree/9.png") },
];
export default function App() {
  return (
    <React.Fragment>
      <div class="three">
        <div class="three__side three__side--front-1">
          <div class="three__title three__title--1">
            <i class="fas fa-paper-plane"></i>

            <h4 class="text">Koh Kooding to Luxury</h4>
          </div>
        </div>
        <div class="three__side three__side--back three__side--back-1">
          <div class="three__cta">
            <MDBCarousel showControls dealy={1000} style={{ height: "100%" }}>
              {images.map((image) => (
                <MDBCarouselItem
                  key={image.id}
                  className="card1"
                  itemId={image.id}
                  src={image.src}
                  alt="..."
                />
              ))}
            </MDBCarousel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import React from "react";
import "./ImagesHover.css";


import "./css/card8.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

const images = [
  { id: 1, src: require("./subeight/1.png") },
  { id: 2, src: require("./subeight/2.png") },
  { id: 3, src: require("./subeight/3.png") },
  { id: 4, src: require("./subeight/4.png") },
  { id: 5, src: require("./subeight/5.png") },
  { id: 6, src: require("./subeight/6.png") },
  { id: 7, src: require("./subeight/7.png") },
  { id: 8, src: require("./subeight/8.png") },
];

export default function App() {
  return (
    <>
      <div class="eight">
        <div class="eight__side eight__side--front-1">
          <div class="eight__title eight__title--1">
            <i class="fas fa-paper-plane"></i>

            <h4 class="text">Turtle Luxe and Glittering Gold</h4>
          </div>
        </div>
        <div class="eight__side eight__side--back eight__side--back-1">
          <div class="eight__cta">
          <MDBCarousel showControls dealy={1000} style={{ height: "100%" }}>
          {images.map((image) => (
            <MDBCarouselItem
              key={image.id}
              className="card1"
              itemId={image.id}
              src={image.src}
              alt="..."
            />
          ))}
        </MDBCarousel>
          </div>
        </div>
      </div>
    </>
  );
}

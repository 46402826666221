import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";

import image1 from "../../../img/2.png";
import detail1 from "../../../img/detail1-1.jfif";

const Container = styled.div((props) => ({
  position: "fixed",
  width: "calc(100% - 40vh - 80px)",
  height: "calc(100% - 40px)",
  top: "0",
  left: "calc(40vh + 40px)",
  display: props.visible ? "block" : "none",
  zIndex: "11",
  padding: "20px",
  backgroundColor: "black",
  color: "white",
}));

const MyImg = styled.img((props) => ({
  width: "100%",
  height: "100%",
}));

const ImageDetail = ({ visible, showSignupModal }) => {
  return (
    <Container visible={visible}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h3>Paris, France</h3>
                  <p>
                    Exclusive limited editions of carefully selected
                    high-quality apparel and lifestyle products tied to our
                    shows and brand on a regular basis
                  </p>
                  <Button
                    className="signup-btn"
                    variant="contained"
                    color="primary"
                    onClick={showSignupModal}
                  >
                    Go to shop
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <MyImg src={image1} alt="" />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              Exclusive limited editions of carefully selected high-quality
              apparel and lifestyle products tied to our shows and brand on a
              regular basisExclusive limited editions of carefully selected
              high-quality apparel and lifestyle products tied to our shows and
              brand on a regular basisExclusive limited editions of carefully
              selected high-quality apparel and lifestyle products tied to our
              shows and brand on a regular basisExclusive limited editions of
              carefully selected high-quality apparel and lifestyle products
              tied to our shows and brand on a regular basisExclusive limited
              editions of carefully selected high-quality apparel and lifestyle
              products tied to our shows and brand on a regular basisExclusive
              limited editions of carefully selected high-quality apparel and
              lifestyle products tied to our shows and brand
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <MyImg src={detail1} alt="" />
            </Grid>
            <Grid item xs={6}>
              <MyImg src={detail1} alt="" />
            </Grid>
            <Grid item xs={6}>
              <MyImg src={detail1} alt="" />
            </Grid>
            <Grid item xs={6}>
              <MyImg src={detail1} alt="" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ImageDetail;

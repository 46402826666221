import React, { useEffect, useRef } from "react";
import gsap from "gsap";

import Imageone from "./Imageone";
import Imagetwo from "./Imagetwo";
import Imagethree from "./Imagethree";
import Imagefour from "./Imagefour";
import Imagefive from "./Imagefive";
import Imagesix from "./Imagesix";
import Imageseven from "./Imageseven";
import Imageeight from "./Imageeight";
import Imagenine from "./Imagenine";
import Imageten from "./Imageten";
import Imageeleven from "./Imageeleven";
import Imagetwelve from "./Imagetwelve";
import Imagethirteen from "./Imagethirteen";
import Imagefourteen from "./Imagefourteen";
import Imagefifteen from "./Imagefifteen";
import Imagesixteen from "./Imagesixteen";
import Imageseventeen from "./Imageseventeen";
import Imageeighteen from "./Imageeighteen";
import Imageninteen from "./Imagenineteen";
import Imagetwenty from "./Imagetwenty";
import Imagetwentyone from "./Imagetwentyone";
import Imagetwentytwo from "./Imagetwentytwo";
import Imagetwentythree from "./Imagetwentythree";
import Imagetwentyfour from "./Imagetwentyfour";
import Imagetwentyfive from "./Imagetwentyfive";
import Imagetwentysix from "./Imagetwentysix";
import Imagetwentyseven from "./Imagetwentyseven";
import Imagetwentyeight from "./Imagetwentyeight";

import { Grid } from "@mui/material";

function MainGallery() {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;

    const onScroll = () => {
      const scrollTop = container.scrollTop;
      const targetY =
        (scrollTop / container.scrollHeight) *
        (container.scrollHeight - window.innerHeight);

      gsap.to(container, { duration: 0.5, scrollTo: { y: targetY } });
    };

    container.addEventListener("scroll", onScroll);

    return () => {
      container.removeEventListener("scroll", onScroll);
    };
  }, []);
  return (
    <div ref={containerRef}>
  
      <Grid container spacing={0}>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imageone />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagetwo />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagethree />
        </Grid>
        <Grid item md={8} lg={8} xs={8} sm={8}>
          <Imagefour />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagefive />
        </Grid>

        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagesix />
        </Grid>
        <Grid item md={8} lg={8} xs={8} sm={8}>
          <Imageseven />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imageeight />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagenine />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imageten />
        </Grid>

        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagetwelve />
        </Grid>
        <Grid item md={8} lg={8} xs={8} sm={8}>
          <Imageeleven />
        </Grid>

        <Grid item md={8} lg={8} xs={8} sm={8}>
          <Imagethirteen />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagefourteen />
        </Grid>

        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagefifteen />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagesixteen />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imageseventeen />
        </Grid>

        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imageninteen />
        </Grid>
        <Grid item md={8} lg={8} xs={8} sm={8}>
          <Imageeighteen />
        </Grid>

        <Grid item md={8} lg={8} xs={8} sm={8}>
          <Imagetwenty />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagetwentyone />
        </Grid>

        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagetwentythree />
        </Grid>
        <Grid item md={8} lg={8} xs={8} sm={8}>
          <Imagetwentytwo />
        </Grid>

        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagetwentyfour />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagetwentyfive />
        </Grid>

        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagetwentysix />
        </Grid>

        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imagetwentyseven />
        </Grid>
        <Grid item md={8} lg={8} xs={8} sm={8}>
          <Imagetwentytwo />
        </Grid>
        <Grid item md={8} lg={8} xs={8} sm={8}>
          <Imagethirteen />
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Imageeight />
        </Grid>
      </Grid>
    </div>
  );
}

export default MainGallery;

import React from "react";
import "./ImagesHover.css";
import "./css/card5.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

const images = [
  { id: 1, src: require("./subfive/1.png") },
  { id: 3, src: require("./subfive/3.png") },
  { id: 4, src: require("./subfive/4.png") },
  { id: 5, src: require("./subfive/5.png") },
  { id: 6, src: require("./subfive/6.png") },
  { id: 7, src: require("./subfive/7.png") },
  { id: 8, src: require("./subfive/8.png") },
  { id: 9, src: require("./subfive/9.png") },
];

export default function App() {
  return (
    <>
      <div class="five">
        <div class="five__side five__side--front-1">
          <div class="five__title five__title--1">
            <i class="fas fa-paper-plane"></i>

            <h4 class="text">Tokyo Nights</h4>
          </div>
        </div>
        <div class="five__side five__side--back five__side--back-1">
          <div class="five__cta">
            <MDBCarousel showControls dealy={1000} style={{ height: "100%" }}>
              {images.map((image) => (
                <MDBCarouselItem
                  key={image.id}
                  className="card1"
                  itemId={image.id}
                  src={image.src}
                  alt="..."
                />
              ))}
            </MDBCarousel>
          </div>
        </div>
      </div>
    </>
  );
}

import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import Vector from "./Vector.png";
import Logo from "./Logo.png";
import card1 from "./card1.png";
import card2 from "./card2.png";
import card3 from "./card3.png";
import card4 from "./card4.png";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2500,
});
const MissionStatement = () => {
  return (
    <div className="">
      <br />
      <br />
      <br />
      <br />
      <div className="container">
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <br />
            <br />
            <div data-aos="fade-right">
              {" "}
              <MDBCard
                style={{
                  Width: "100%",
                  height: "",
                  background: "#1E3231",
                  borderRadius: "30px",
                }}
              >
                <MDBRow className="g-0">
                  <MDBCol md="12">
                    <MDBCardBody>
                      <br />
                      <br />
                      <br />
                      <br />
                      <MDBCardTitle
                        style={{
                          color: "#fff",
                          fontSize: "44px",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        For Celebs
                      </MDBCardTitle>
                      <br />
                      <br />
                      <br />
                      <br />{" "}
                      <MDBCardTitle
                        style={{
                          color: "#fff",
                          fontSize: "84px",
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        Coming Soon
                      </MDBCardTitle>
                      <span>
                        <br />
                        <br />
                        <br />
                        <br />
                        <Divider
                          variant="middle"
                          style={{ color: "#fff", background: "#fff" }}
                        />
                      </span>
                    </MDBCardBody>
                  </MDBCol>
                </MDBRow>
              </MDBCard>
            </div>
          </Grid>
        </Grid>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default MissionStatement;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sidebarVisible: true,
}

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        showSidebar: (state) => {
            state.sidebarVisible = true;
        },
        hideSidebar: (state) => {
            state.sidebarVisible = false;
        },
    }
});

export const {
    showSidebar,
    hideSidebar,
} = chatSlice.actions;

export default chatSlice.reducer;
import React from "react";
import "./ImagesHover.css";

import "./css/card22.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

const images = [
  { id: 1, src: require("./subtwentytwo/1.png") },
  { id: 2, src: require("./subtwentytwo/2.png") },
  { id: 3, src: require("./subtwentytwo/3.png") },
  { id: 4, src: require("./subtwentytwo/4.png") },
  { id: 5, src: require("./subtwentytwo/5.png") },
  { id: 6, src: require("./subtwentytwo/6.png") },
];
export default function App() {
  return (
    <>
      <div class="twentytwo">
        <div class="twentytwo__side twentytwo__side--front-1">
          <div class="twentytwo__title twentytwo__title--1">
            <h4 class="text">Reeling in the Big Ben: A London Fishing Trip!</h4>
          </div>
        </div>
        <div class="twentytwo__side twentytwo__side--back twentytwo__side--back-1">
          <div class="twentytwo__cta">
            <MDBCarousel showControls dealy={1000} style={{ height: "100%" }}>
              {images.map((image) => (
                <MDBCarouselItem
                  key={image.id}
                  className="card1"
                  itemId={image.id}
                  src={image.src}
                  alt="..."
                />
              ))}
            </MDBCarousel>
          </div>
        </div>
      </div>
    </>
  );
}

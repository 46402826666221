import styled from "@emotion/styled";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideSidebar } from "../../redux/slices/chatSlice";
import { hideModal } from "../../redux/slices/modalSlice";
import device from "../../util/device";

const MyContainer = styled.div(props => `
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    color: white;
    display: ${props.visible ? 'block' : 'none'};
    z-index: 2;
`);

const GoBack = styled.div(props => `
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 27px;
    cursor: pointer;

    @media ${device.laptop} {
        display: none;
    }
`);

const ComingSoon = () => {
    const dispatch = useDispatch();
    const visible = useSelector(state => state.modal.visible);
    console.log('visible', visible)

    const goBack = () => {
        dispatch(hideModal());
    }


    return (
        <MyContainer visible={visible}>
            <h2 style={{color: 'white', zIndex: '5'}}>Coming soon...</h2>
            <GoBack onClick={goBack}>X</GoBack>
        </MyContainer>
    );
}

export default ComingSoon;
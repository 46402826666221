import styled from "@emotion/styled";
import { Button, Paper } from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { hideModal, showModal } from "../redux/slices/modalSlice";
import device from "../util/device";

const MyContainer = styled(Paper)(props => `
    background-color: #1E1F23;
    height: 100%;
    display: ${props.visible ? 'flex' : 'none'};
    flex-direction: column;
    color: #D1D5DB;

    @media ${device.laptop} {
        display: flex;
    }
`);

const ChatContainer = styled.div(props => `
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
`);

const Chat = styled.div(props => `
    background: #343541;
    padding: 10px;
    display: flex;
    gap: 10px;
    margin: 10px;
    margin-left: 15px;
`)

const ChatAvatar1 = styled.div`
    min-width: 30px;
    height: 30px;
    background-color: lightgreen;
`;

const ChatAvatar2 = styled.div`
    min-width: 30px;
    height: 30px;
    background-color: lightblue;
`;

const ChatText = styled.div`
    color: #D1D5DB;
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
`;

const MyButton = styled(Button)(props => ({
    textTransform: 'none',
    borderRadius: '50px',
    backgroundColor: (props.selected ? '#6865FF' : '#0500FF'),
}))

const ChatInput = styled.div(props => `
    height: 30px;
    background-color: #444654;
    margin: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 5px;
`);

const MyInput = styled.input(props => `
    width: 100%;
    background: transparent;
    border: none;
    color: #D1D5DB;

    &:focus-visible {
        outline: none;
    }
`);

const Sidebar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const visible = useSelector(state => state.chat.sidebarVisible);
    
    const [newChatText, setNewChatText] = useState('');
    const [destinations, setDestinations] = useState(null);
    const [chattingContent, setChattingContent] = useState([
        {
            talker: 2,
            text: [
                { str: 'Hi. I am flutterby...' },
                { str: 'What is your name?' }
            ]
        }
    ]);

    const goToDestinations = (index) => {
        setDestinations(index);
        navigate(`/destinations/${index}`);

        setTimeout(() => {
            setChattingContent(content => {
                if (content.length > 3) return content;
                return [
                    { 
                        talker: 2, 
                        text: [{
                            str: 'Try to ask anything'
                        }] 
                    }, 
                    ...content
                ]
            })
        }, 100);
    }

    const msgSend = () => {
        console.log(newChatText)
        if (newChatText === '') return;
        if (chattingContent.length === 1) {
            setChattingContent(content => [
                {
                    talker: 1,
                    text: [{ str: newChatText }]
                },
                ...content
            ]);
            setTimeout(() => {
                setChattingContent(content => [
                    {
                        talker: 2,
                        text: [
                            { str: 'I have traveled everywhere I can show you:' },
                            {
                                btn: 'most popular destinations',
                                click: () => goToDestinations(1)
                            },
                            {
                                btn: 'somewhere hot in august',
                                click: () => goToDestinations(2)
                            },
                            {
                                btn: 'a low budget option',
                                click: () => goToDestinations(3)
                            }]
                    
                    },
                    ...content
                ])
            }, 500);
        } else if (chattingContent.length >= 3) {
            setChattingContent(content => [
                {
                    talker: 2,
                    text: [
                        { str: newChatText }
                    ]
                },
                ...content
            ]);
            dispatch(showModal());
        }
        
        setNewChatText('');
        
    }

    return (
        <MyContainer visible={visible}>
            <ChatContainer>
                {chattingContent.map((content, index) => (
                    <Chat key={index}>
                        {content.talker === 1 ? <ChatAvatar1 /> : <ChatAvatar2 />}
                        <ChatText>
                            {content.text.map((line, index) => {
                                if (line.btn) {
                                    return (
                                        <MyButton
                                            variant='contained'
                                            fullWidth
                                            key={index}
                                            onClick={line.click}
                                            selected={destinations === index ? true: false}
                                        >
                                            {line.btn}
                                        </MyButton>
                                    )
                                } else {
                                    return <div key={index}>{line.str}</div>
                                }
                            })}
                        </ChatText>
                    </Chat>    
                ))}
            </ChatContainer>
            <ChatInput>
                <MyInput
                    onKeyUp={(event) => {
                        if (event.key === 'Enter' || event.keyCode === 13)
                            msgSend();
                    }}
                    value={newChatText}
                    onChange={event => setNewChatText(event.target.value)}
                />
                <svg onClick={msgSend} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http:www.w3.org/2000/svg">
                    <path d="M14.6663 1.33325L7.33301 8.66659M14.6663 1.33325L9.99967 14.6666L7.33301 8.66659M14.6663 1.33325L1.33301 5.99992L7.33301 8.66659" stroke="#ACACBE" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </ChatInput>
        </MyContainer>
    );
}

export default Sidebar;
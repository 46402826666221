import React, { useState, useEffect, lazy, Suspense } from "react";
import { Grid } from "@mui/material";

import "./App.css";
import Header from "./layout/header";
import Sidebar from "./layout/sidebar";

import background from "./images/background.jfif";
import Destinations from "./pages/destinations";
import device, { size } from "./util/device";
import DestinationDetail from "./pages/destinationDetail";
import ComingSoon from "./pages/comingSoon";

import styled from "styled-components";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./Theme";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { gsap, CSSPlugin, Expo } from "gsap";
import Home from "../src/screens/Home";
import ForBusiness from "../src/screens/ForBusiness";
import Becomeinfluencer from "../src/screens/BecomeInfluencer";
import Signin from "../src/screens/Signin";
import Gallery from "../src/screens/GalleryHome";

gsap.registerPlugin(CSSPlugin);

const MyContainer = styled.div(
  (props) => `
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: url(${background});
`
);

const MyGrid = styled(Grid)(
  (props) => `
  height: 100%;
`
);

const Main = styled(Grid)(
  (props) => `
  height: 50%;
  width: 100%;

  @media ${device.laptop} {
    height: 100%;
  }
`
);

function App() {
  const [, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const setWindowDimension = () => {
    setSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener("resize", setWindowDimension);
    return () => {
      window.removeEventListener("resize", setWindowDimension);
    };
  }, []);
  let width = window.innerWidth;
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const count = setInterval(() => {
      setCounter((counter) =>
        counter < 100
          ? counter + 1
          : (clearInterval(count), setCounter(100), reveal())
      );
    }, 10);
  }, []);

  const reveal = () => {
    const t1 = gsap.timeline({
      onComplete: () => {
        console.log("completed");
      },
    });
    t1.to(".follow", {
      width: "100%",
      ease: Expo.easeInOut,
      duration: 1.2,
      delay: 0.7,
    })
      .to(".hide", { opacity: 0, duration: 0.3 })
      .to(".hide", { display: "none", duration: 0.3 })
      .to(".follow", {
        height: "100%",
        ease: Expo.easeInOut,
        duration: 0.7,
        delay: 0.5,
      })
      .to(".content", { width: "100%", ease: Expo.easeInOut, duration: 0.7 })
      .to(".title-lines", { display: "block", duration: 0.1 })
      .to(".title-lines", {
        opacity: 1,
        stagger: 0.15,
        ease: Expo.easeInOut,
        duration: 0.6,
      });
  };

  return (
    <AppContainer>
      <Loading>
        <Follow className="follow"></Follow>
        <ProgressBar
          className="hide"
          id="progress-bar"
          style={{ width: counter + "%" }}
        ></ProgressBar>
        <Count id="count" className="hide">
          {counter}%
        </Count>
      </Loading>

      <Content className="content">
        <ThemeProvider theme={theme}>
          <Router>
            <Routes>
              <Route path="/" exact={true} element={<Home />} />
              <Route path="/Gallery" exact={true} element={<Gallery />} />

              <Route
                path="/forbusiness"
                exact={true}
                element={<ForBusiness />}
              />
              <Route
                path="/Becomeinfluencer"
                exact={true}
                element={<Becomeinfluencer />}
              />
              <Route path="/signin" exact={true} element={<Signin />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </Content>
    </AppContainer>
  );
}

export default App;

const AppContainer = styled.div`
  height: 100vh;
  color: #000000;
  position: relative;
`;
const Loading = styled.div`
  height: 100%;
  width: 100%;
  background-color: #121212;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
`;
const Follow = styled.div`
  position: absolute;
  background-color: #1e1f23;

  height: 2px;
  width: 0;
  left: 0;
  z-index: 2;
`;

const ProgressBar = styled.div`
  position: absolute;
  left: 0;
  background-color: #fff;
  height: 2px;
  width: 0;
  transition: 0.4s ease-out;
`;

const Count = styled.p`
  position: absolute;
  font-size: 130px;
  color: #fff;
  transform: translateY(-15px);
  font-weight: 500;
`;

const Content = styled.div`
  width: 0;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #000;
  padding: auto;

  z-index: 2;
  overflow-x: hidden;

  color: #000;

  p {
    text-align: center;
    font-size: 104px;
    opacity: 0;
    display: none;
    font-weight: 500;
    margin: 0;
  }
`;

import React from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import "./card.css";

const images = [
  { id: 1, src: require("./subone/1.png") },
  { id: 2, src: require("./subone/2.png") },
  { id: 3, src: require("./subone/3.png") },
  { id: 4, src: require("./subone/4.png") },
  { id: 5, src: require("./subone/5.png") },
  { id: 6, src: require("./subone/6.png") },
  { id: 7, src: require("./subone/7.png") },
  { id: 8, src: require("./subone/8.png") },
  { id: 9, src: require("./subone/9.png") },
  { id: 10, src: require("./subone/10.png") },
  { id: 11, src: require("./subone/11.png") },
  { id: 12, src: require("./subone/12.png") },
];

export default function App() {
  return (
    <React.Fragment>
      <div className="cardz">
        <div className="cardz__side cardz__side--front-1">
          <div className="cardz__title cardz__title--1">
            <h4 className="text">A Culinary Sojourn in the City of Lights</h4>
          </div>
        </div>
        <div className="cardz__side cardz__side--back cardz__side--back-1">
          <div className="cardz__cta">
            <MDBCarousel showControls dealy={1000} style={{ height: "100%" }}>
              {images.map((image) => (
                <MDBCarouselItem
                  key={image.id}
                  className="card1"
                  itemId={image.id}
                  src={image.src}
                  alt="..."
                />
              ))}
            </MDBCarousel>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

import React from "react";
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Divider from "@mui/material/Divider";
import { Grid } from "@mui/material";
import Vector from "./Vector.png";
import Logo from "./Logo.png";
import card1 from "./card1.png";
import card2 from "./card2.png";
import card3 from "./card3.png";
import card4 from "./card4.png";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init({
  duration: 2500,
});
const MissionStatement = () => {
  return (
    <div>
      <br />
      <br />
      <h1
        style={{
          color: "#fff",
          fontSize: "34px",
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        Celebrity
      </h1>
      <br />
      <br />

      <div className="container">
        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <br />
            <br />
            <div class="our-team">
              <div class="picture">
                <img
                  class="img-fluid"
                  src="https://picsum.photos/130/130?image=1027"
                />
              </div>
              <div class="team-content">
                <h3 class="name">Michele Miller</h3>
                <h4 class="title">Web Developer</h4>
              </div>
              <ul class="social">
                <li>
                  <FacebookIcon />
                </li>
                
                <li>
                  <LinkedInIcon />
                </li>
              </ul>
            </div>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
          <br />
          <br />
          <div class="our-team">
            <div class="picture">
              <img
                class="img-fluid"
                src="https://picsum.photos/130/130?image=1027"
              />
            </div>
            <div class="team-content">
              <h3 class="name">Michele Miller</h3>
              <h4 class="title">Web Developer</h4>
            </div>
            <ul class="social">
              <li>
                <FacebookIcon />
              </li>
              
              <li>
                <LinkedInIcon />
              </li>
            </ul>
          </div>
        </Grid>
        <Grid item lg={3} md={3} sm={12} xs={12}>
        <br />
        <br />
        <div class="our-team">
          <div class="picture">
            <img
              class="img-fluid"
              src="https://picsum.photos/130/130?image=1027"
            />
          </div>
          <div class="team-content">
            <h3 class="name">Michele Miller</h3>
            <h4 class="title">Web Developer</h4>
          </div>
          <ul class="social">
            <li>
              <FacebookIcon />
            </li>
            
            <li>
              <LinkedInIcon />
            </li>
          </ul>
        </div>
      </Grid>
      <Grid item lg={3} md={3} sm={12} xs={12}>
      <br />
      <br />
      <div class="our-team">
        <div class="picture">
          <img
            class="img-fluid"
            src="https://picsum.photos/130/130?image=1027"
          />
        </div>
        <div class="team-content">
          <h3 class="name">Michele Miller</h3>
          <h4 class="title">Web Developer</h4>
        </div>
        <ul class="social">
          <li>
            <FacebookIcon />
          </li>
          
          <li>
            <LinkedInIcon />
          </li>
        </ul>
      </div>
    </Grid>
        
        </Grid>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export default MissionStatement;

import { Button } from "@mui/material";
import styled from "@emotion/styled";

import Item from "./Item";
import itemBack1 from "../../img/item-back1.png";
import itemBack2 from "../../img/item-back2.png";
import itemBack3 from "../../img/item-back2.png";
import itemBack4 from "../../img/item-back4.png";

import background3 from "../../img/background3.png";
import background4 from "../../img/background4.png";
import logo from "../../img/logo.png";
import destination from "../../img/destination.svg";

import "./signupScreen.css";
import { useEffect, useRef, useState } from "react";
import SwiperModal from "./SwiperModal";
import SignupModal from "./SignupModal";
import ImageDetail from "./ImageDetail";

const ChatSideBar = styled.div((props) => ({
  width: "40vh",
  height: "calc(100vh - 0vh)",
  backgroundColor: "#1E1F23",
  padding: "30px 20px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  position: "fixed",
  top: "0",
  left: props.show ? "0" : "calc(-40vh - 40px)",
  transition: "left 0.2s",
}));

const ChatSideBarHeader = styled.div`
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 48px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(36, 255, 216, 0.79) 33.33%,
    rgba(194, 163, 255, 0.79) 99.47%,
    rgba(196, 163, 255, 0.79) 99.48%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ChattingBox = styled.div`
  padding: 7px;
  overflow: auto;
  height: -webkit-fill-available;
  display: flex;
  flex-direction: column-reverse;
  gap: 20px;
`;

const Chat = styled.div`
  background: #343541;
  padding: 10px;
  display: flex;
  gap: 10px;
`;

const ChatAvatar1 = styled.div`
  min-width: 30px;
  height: 30px;
  background-color: lightgreen;
`;

const ChatAvatar2 = styled.div`
  min-width: 30px;
  height: 30px;
  background-color: lightblue;
`;

const ChatText = styled.div`
  color: #d1d5db;
  font-family: "Segoe UI";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const NewChat = styled.div`
  background: #444654;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
`;

const NewChatInput = styled.input((props) => ({
  width: "-webkit-fill-available",
  background: "transparent",
  border: "none",
  color: "white",
  ":focus-visible": {
    outline: "none",
  },
}));

const MyButton = styled(Button)((props) => ({
  textTransform: "none",
}));

const SignupScreen = () => {
  const exploreNewDestinationRef = useRef(null);

  const [chatSidebarVisible, setChatSidebarVisible] = useState(false);

  const [newChatText, setNewChatText] = useState("");

  const [imgSwiperVisible, setImgSwiperVisible] = useState(null);

  const [signupModalVisible, setSignupModalVisible] = useState(false);

  const [imageDetailVisible, setImageDetailVisible] = useState(false);

  const [chattingContent, setChattingContent] = useState([
    {
      talker: 2,
      text: [
        { str: "Hi. Welcome to Flutterby." },
        { str: "What are you going to do?" },
      ],
    },
  ]);

  const showImgSwiper = (img) => {
    setImgSwiperVisible(img);
    setTimeout(() => {
      setChattingContent((content) => {
        if (content.length > 3) return content;
        return [
          {
            talker: 2,
            text: [
              {
                btn: "Try to ask anything",
                click: () => setSignupModalVisible(true),
              },
            ],
          },
          ...content,
        ];
      });
    }, 100);
  };

  const msgSend = () => {
    if (newChatText === "") return;
    if (chattingContent.length === 1) {
      setChattingContent((content) => [
        { talker: 1, text: [{ str: newChatText }] },
        ...content,
      ]);
      setTimeout(() => {
        setChattingContent((content) => [
          {
            talker: 2,
            text: [
              { str: "Where are you going?" },
              { btn: "Somewhere hot in august", click: () => showImgSwiper(1) },
              {
                btn: "Fireworks across the world",
                click: () => showImgSwiper(2),
              },
              {
                btn: "A cheaper option than Thailand",
                click: () => showImgSwiper(3),
              },
            ],
          },
          ...content,
        ]);
      }, 500);
    } else if (chattingContent.length === 3) {
    }

    setNewChatText("");
  };

  useEffect(() => {
    const options = {
      rootMargin: "0px",
      threshold: 0.5,
    };
    const observer = new IntersectionObserver((entries) => {
      const [entry] = entries;
      if (entry.intersectionRatio > 0.5) {
        setChatSidebarVisible(true);
      } else {
        setChatSidebarVisible(false);
        setImageDetailVisible(false);
      }
    }, options);
    if (exploreNewDestinationRef.current)
      observer.observe(exploreNewDestinationRef.current);
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      if (exploreNewDestinationRef.current)
        observer.unobserve(exploreNewDestinationRef.current);
    };
  }, [exploreNewDestinationRef]);

  return (
    <div className="">
      <div className="explore-new-destinations" ref={exploreNewDestinationRef}>
        {imgSwiperVisible ? (
          <SwiperModal
            imageDetailShow={() => setImageDetailVisible(true)}
            img={imgSwiperVisible}
          />
        ) : (
          <img src={destination} className="destination" alt="destination" />
        )}

        <SignupModal
          visible={signupModalVisible}
          setVisible={setSignupModalVisible}
        />

        <ChatSideBar show={chatSidebarVisible}>
          <ChatSideBarHeader>
            <img src={logo} alt="logo" width={30} height={30} />
            <div>Flutterby</div>
          </ChatSideBarHeader>
          <ChattingBox>
            {chattingContent.map((content, index) => (
              <Chat key={index}>
                {content.talker === 1 ? <ChatAvatar1 /> : <ChatAvatar2 />}
                <ChatText>
                  {content.text.map((line, index) => {
                    if (line.btn) {
                      return (
                        <MyButton
                          variant="contained"
                          fullWidth
                          key={index}
                          onClick={line.click}
                        >
                          {line.btn}
                        </MyButton>
                      );
                    } else {
                      return <div key={index}>{line.str}</div>;
                    }
                  })}
                </ChatText>
              </Chat>
            ))}
          </ChattingBox>
          <NewChat>
            <NewChatInput
              onKeyUp={(event) => {
                if (event.key === "Enter" || event.keyCode === 13) msgSend();
              }}
              value={newChatText}
              onChange={(event) => setNewChatText(event.target.value)}
            />
            <svg
              onClick={msgSend}
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.6663 1.33325L7.33301 8.66659M14.6663 1.33325L9.99967 14.6666L7.33301 8.66659M14.6663 1.33325L1.33301 5.99992L7.33301 8.66659"
                stroke="#ACACBE"
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </NewChat>
        </ChatSideBar>

        <ImageDetail
          visible={imageDetailVisible}
          showSignupModal={() => setSignupModalVisible(true)}
        />
      </div>
    </div>
  );
};

export default SignupScreen;

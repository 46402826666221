import React from "react";
import "./ImagesHover.css";
import "./css/card18.css";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";

const images = [
  { id: 1, src: require("./subeighteen/1.png") },
  { id: 2, src: require("./subeighteen/2.png") },
  { id: 3, src: require("./subeighteen/3.png") },
  { id: 4, src: require("./subeighteen/4.png") },
  { id: 5, src: require("./subeighteen/5.png") },
  { id: 6, src: require("./subeighteen/6.png") },
  { id: 7, src: require("./subeighteen/7.png") },
  { id: 8, src: require("./subeighteen/8.png") },
  { id: 9, src: require("./subeighteen/9.png") },
];

export default function App() {
  return (
    <>
      <div class="eighteen">
        <div class="eighteen__side eighteen__side--front-1">
          <div class="eighteen__title eighteen__title--1">
            <i class="fas fa-paper-plane"></i>

            <h4 class="text">
              Swingin' in the Sand: A Dubai Tennis Adventure!
            </h4>
          </div>
        </div>
        <div class="eighteen__side eighteen__side--back eighteen__side--back-1">
          <div class="eighteen__cta">
            <MDBCarousel showControls dealy={1000} style={{ height: "100%" }}>
              {images.map((image) => (
                <MDBCarouselItem
                  key={image.id}
                  className="card1"
                  itemId={image.id}
                  src={image.src}
                  alt="..."
                />
              ))}
            </MDBCarousel>
          </div>
        </div>
      </div>
    </>
  );
}
